import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_APPOINTMENT, API_APPOINTMENT_INDEX } from "core/endpoints";
import { appointmentActions } from "store/reducers/appointmentSlice";
import axios from "utils/axios";

export const getAppointments = createAsyncThunk("appointment/index", async () => {
   const res = await axios.get(API_APPOINTMENT_INDEX);
   return res.data;
});

export const createAppointment = createAsyncThunk("appointment/create", async (data: any, thunkAPI) => {
   try {
      const res = await axios.post(API_APPOINTMENT, data);
      if (!res.data.status) throw res.data;
      thunkAPI.dispatch(appointmentActions.create(res.data.result));
      return res.data;
   } catch (err) {
      return err;
   }
});

export const updateAppointment = createAsyncThunk("appointment/update", async (data: any, thunkAPI) => {
   try {
      const res = await axios.put(API_APPOINTMENT, data);
      if (!res.data.status) throw res.data;
      return res.data;
   } catch (err) {
      return err;
   }
});

export const deleteAppointment = createAsyncThunk("appointment/delete", async (data: any, thunkAPI) => {
   try {
      const res = await axios.delete(API_APPOINTMENT, { data });
      if (!res.data.status) throw res.data;
      return res.data;
   } catch (err) {
      return err;
   }
});
