import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAnnouncements } from "store/actions/announcementActions";
import { AnnouncementDto, AnnouncementModel } from "store/types/announcement";

const initialState: AnnouncementDto = {
   isLoading: false,
   current: undefined,
   index: undefined,
};

export const announcementSlice = createSlice({
   name: "announcements",
   initialState,
   reducers: {
      create: (state, action: PayloadAction<AnnouncementModel>) => {
         state.index?.push(action.payload);
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAnnouncements.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getAnnouncements.fulfilled, (state, action) => {
            state.index = action.payload.result;
         });
   },
});

export const announcementActions = announcementSlice.actions;
export default announcementSlice.reducer;
