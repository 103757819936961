import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SnackInitialState, SnackModel } from "store/types/global";

const initialState: SnackModel = SnackInitialState;

export const snackSlice = createSlice({
   name: "snack",
   initialState,
   reducers: {
      success: (state, action) => {
         state.message = action.payload || "Success!";
         state.success = true;
         state.open = true;
      },
      failed: (state, action) => {
         state.message = action.payload || "Failed!";
         state.success = false;
         state.open = true;
      },
      custom: (state, action: PayloadAction<SnackModel>) => {
         state.message = action.payload.message;
         state.success = action.payload.success;
         state.open = action.payload.open;
      },
      close: (state) => {
         state.open = false;
      },
   },
});

export const snackActions = snackSlice.actions;
export default snackSlice.reducer;
