import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_PROJECT, API_PROJECT_INDEX } from "core/endpoints";
import { projectActions } from "store/reducers/projectSlice";
import axios from "utils/axios";

export const getProjects = createAsyncThunk("project/index", async () => {
   const res = await axios.get(API_PROJECT_INDEX);
   return res.data;
});

export const createProject = createAsyncThunk("project/create", async (data: any, thunkAPI) => {
   try {
      const res = await axios.post(API_PROJECT, data);
      if (!res.data.status) throw res.data;
      thunkAPI.dispatch(projectActions.create(res.data.result));
      return res.data;
   } catch (err) {
      return err;
   }
});

export const updateProject = createAsyncThunk("project/update", async (data: any, thunkAPI) => {
   try {
      const res = await axios.put(API_PROJECT, data);
      if (!res.data.status) throw res.data;
      return res.data;
   } catch (err) {
      return err;
   }
});

export const deleteProject = createAsyncThunk("project/delete", async (data: any, thunkAPI) => {
   try {
      const res = await axios.delete(API_PROJECT, { data });
      if (!res.data.status) throw res.data;
      return res.data;
   } catch (err) {
      return err;
   }
});
