import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAppointments } from "store/actions/appointmentActions";
import { AppointmentDto, AppointmentModel } from "store/types/appointment";

const initialState: AppointmentDto = {
   isLoading: false,
   current: undefined,
   index: undefined,
};

export const appointmentSlice = createSlice({
   name: "appointment",
   initialState,
   reducers: {
      create: (state, action: PayloadAction<AppointmentModel>) => {
         state.index?.push(action.payload);
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAppointments.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getAppointments.fulfilled, (state, action) => {
            state.index = action.payload.result;
         });
   },
});

export const appointmentActions = appointmentSlice.actions;
export default appointmentSlice.reducer;
