import LazyLoad from "components/animation/lazyLoad";
import AppRoutes from "core/routes";
import { Suspense, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { getAppInfo } from "store/actions/appinfoActions";
import { AppInfoDto } from "store/types/appinfo";
import { useAppDispatch, useAppSelector } from "utils/redux-hooks";

let mtAppInfo = false;
function App() {
   const dispatch = useAppDispatch();
   const AppInfo = useAppSelector<AppInfoDto>((state) => state.appinfo);

   useEffect(() => {
      if (!mtAppInfo) {
         dispatch(getAppInfo());
         mtAppInfo = true;
      }
   }, [dispatch]);

   return (
      <Suspense fallback={<LazyLoad />}>
         <Router>
            <AppRoutes />
         </Router>
      </Suspense>
   );
}

export default App;
