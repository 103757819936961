/**
 * Redux hooks from redux
 */
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useNavigate as navigation } from "react-router";
import type { AppDispatch, RootState } from "store";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useNavigate = () => navigation();
