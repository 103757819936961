import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDocuments } from "store/actions/documentActions";
import { DocumentDto, UserDocumentModel } from "store/types/user";

const initialState: DocumentDto = {
   isLoading: false,
   current: null,
   index: null,
};

export const documentSlice = createSlice({
   name: "documents",
   initialState,
   reducers: {
      create: (state, action: PayloadAction<UserDocumentModel>) => {
         state.index?.push(action.payload);
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getDocuments.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getDocuments.fulfilled, (state, action) => {
            state.index = action.payload.result;
         });

      // builder
      //    .addCase(getCurrentDocuments.pending, (state) => {
      //       state.isLoading = true;
      //    })
      //    .addCase(getCurrentDocuments.fulfilled, (state, action) => {
      //       state.current = action.payload.result;
      //    });
   },
});

export const userActions = documentSlice.actions;
export default documentSlice.reducer;
