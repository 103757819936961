import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_USER, API_USER_INDEX, API_USER_STATUS } from "core/endpoints";
import { userActions } from "store/reducers/userSlice";
import axios from "utils/axios";

export const getUsers = createAsyncThunk("user/index", async () => {
   const res = await axios.get(API_USER_INDEX);
   return res.data;
});

export const getCurrentUser = createAsyncThunk("user/current", async (data: any, thunkAPI) => {
   try {
      const res = await axios.get(`${API_USER}/${data}`);
      if (!res.data.status) throw res.data;
      return res.data;
   } catch (err) {
      return err;
   }
});

export const createUser = createAsyncThunk("user/create", async (data: any, thunkAPI) => {
   try {
      const res = await axios.post(API_USER, data);
      if (!res.data.status) throw res.data;
      thunkAPI.dispatch(userActions.create(res.data.result));
      return res.data;
   } catch (err) {
      return err;
   }
});

export const updateUser = createAsyncThunk("user/update", async (data: any, thunkAPI) => {
   try {
      const res = await axios.put(API_USER, data);
      if (!res.data.status) throw res.data;
      return res.data;
   } catch (err) {
      return err;
   }
});

export const deleteUser = createAsyncThunk("user/delete", async (data: any, thunkAPI) => {
   try {
      const res = await axios.delete(API_USER, { data });
      if (!res.data.status) throw res.data;
      return res.data;
   } catch (err) {
      return err;
   }
});

export const updateUserCredentials = createAsyncThunk("user/update/credentials", async (data: any, thunkAPI) => {
   try {
      const res = await axios.patch(API_USER, data);
      if (!res.data.status) throw res.data;
      return res.data;
   } catch (err) {
      return err;
   }
});

export const updateUserStatus = createAsyncThunk("user/update/status", async (data: any, thunkAPI) => {
   try {
      const res = await axios.put(API_USER_STATUS, data);
      if (!res.data.status) throw res.data;
      return res.data;
   } catch (err) {
      return err;
   }
});
