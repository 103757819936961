import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_LOGIN } from "core/endpoints";
import { store } from "store";
import { authActions } from "store/reducers/authSlice";
import { LoginModel } from "store/types/auth";
import { asyncStorage } from "utils/async-storage";
import axios from "utils/axios";

export const loginHandler = createAsyncThunk("auth/login", async (data: LoginModel, thunkAPI) => {
   const res = await axios.post(API_LOGIN, data);
   if (!res.data.status) {
      thunkAPI.dispatch(authActions.loginFailed());
      asyncStorage.removeItem("AuthStore");
   } else {
      thunkAPI.dispatch(authActions.loginSuccess(res.data.result));
      asyncStorage.setItem("AuthStore", JSON.stringify(res.data.result));
   }
   return res.data;
});

export const authHandler = createAsyncThunk("auth/asynced", async (any, thunkAPI) => {
   const state = store.getState();
   let token = state.auth.access_token || null;

   if (!token) {
      let auth = await asyncStorage.getItem("AuthStore");
      if (!auth) return;
      thunkAPI.dispatch(authActions.loginSuccess(auth));
      token = auth.access_token;
   }

   return { token };
});
