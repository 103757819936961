export const SERVER = process.env.REACT_APP_API_URL;

export const API_APPINFO = "/api/appinfo";
export const API_LOGIN = "/api/login";
export const API_USER = "/api/user";
export const API_ANNOUNCEMENT = "/api/announcement";
export const API_APPOINTMENT = "/api/appointment";
export const API_DASHBOARD = "/api/dashboard";
export const API_PROJECT = "/api/project";
export const API_DOCUMENT = "/api/document";

export const API_APPINFO_INDEX = `${API_APPINFO}/index`;
export const API_USER_INDEX = `${API_USER}/index`;
export const API_DOCUMENT_INDEX = `${API_DOCUMENT}/index`;
export const API_ANNOUNCEMENT_INDEX = `${API_ANNOUNCEMENT}/index`;
export const API_APPOINTMENT_INDEX = `${API_APPOINTMENT}/index`;
export const API_PROJECT_INDEX = `${API_PROJECT}/index`;

export const API_USER_STATUS = `${API_USER}/status/`;
export const API_DOCUMENT_STATUS = `${API_DOCUMENT}/status`;

export const STORAGE = `${SERVER}/storage`;
export const LOGO = `${STORAGE}/initial/bms.png`;
