export const {
   REACT_APP_ROLE_ADMIN: ADMIN,
   REACT_APP_ROLE_OFFICIAL: OFFICER,
   REACT_APP_ROLE_HEALTH: HEALTH,
   REACT_APP_ROLE_EMERGENCY: EMERGENCY,
   REACT_APP_ROLE_RESIDENT: RESIDENT,
} = process.env;

// MGA ALLOWED NA USER SA WEB
export const PERMISSION = [ADMIN, OFFICER, HEALTH, EMERGENCY];

// DASHBOARD SCREEN
export const AUTH_DASHBOARD = [ADMIN, OFFICER];
export const AUTH_OFFICIAL = [ADMIN, OFFICER];
export const AUTH_RESIDENT = [ADMIN, OFFICER];
export const AUTH_ANNOUNCEMENT = [ADMIN, OFFICER, HEALTH];
export const AUTH_APPOINTMENT = [ADMIN, OFFICER, HEALTH];
export const AUTH_PROJECT = [ADMIN, OFFICER];
export const AUTH_DOCUMENT = [ADMIN, OFFICER];
export const AUTH_APPINFO = [ADMIN, OFFICER];
export const AUTH_MANAGEMENT = [ADMIN, OFFICER];
export const AUTH_RESCUE = [ADMIN, OFFICER, EMERGENCY];
