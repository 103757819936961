import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAppInfo } from "store/actions/appinfoActions";
import { AppInfoDto, AppInfoModel } from "store/types/appinfo";

const initialState: AppInfoDto = {
   isLoading: false,
   current: undefined,
   index: undefined,
};

export const appinfoSlice = createSlice({
   name: "appinfo",
   initialState,
   reducers: {
      create: (state, action: PayloadAction<AppInfoModel>) => {
         state.index?.push(action.payload);
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAppInfo.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getAppInfo.fulfilled, (state, action) => {
            state.current = action.payload.result;
         });
   },
});

export const appinfoActions = appinfoSlice.actions;
export default appinfoSlice.reducer;
