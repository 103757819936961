import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthModel } from "store/types/auth";
import { asyncStorage } from "utils/async-storage";

const initialState: AuthModel = {
   isLoggedIn: false,
   isLoading: false,
   _id: null,
   access_token: null,
   name: null,
   level: null,
};

export const authSlice = createSlice({
   name: "auth",
   initialState,
   reducers: {
      login: (state) => {
         state.isLoading = true;
      },
      loginSuccess: (state, action: PayloadAction<AuthModel>) => {
         state.isLoggedIn = true;
         state.isLoading = false;
         state._id = action.payload._id;
         state.access_token = action.payload.access_token;
         state.level = action.payload.level;
      },
      loginFailed: (state) => {
         state.isLoggedIn = false;
         state.isLoading = false;
         state._id = null;
         state.access_token = null;
      },
      logout: (state) => {
         asyncStorage.removeItem("AuthStore");
         state.isLoggedIn = false;
         state.isLoading = false;
         state._id = null;
         state.access_token = null;
      },
   },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
