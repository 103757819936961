import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_APPINFO, API_APPINFO_INDEX } from "core/endpoints";
import axios from "utils/axios";

export const getAppInfo = createAsyncThunk("appinfo/current", async () => {
   const res = await axios.get(API_APPINFO_INDEX);
   return res.data;
});

export const updateAppInfo = createAsyncThunk("appinfo/update", async (data: any, thunkAPI) => {
   try {
      const res = await axios.put(API_APPINFO, data);
      if (!res.data.status) throw res.data;
      return res.data;
   } catch (err) {
      return err;
   }
});
