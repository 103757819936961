import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import announcementReducer from "store/reducers/announcementSlice";
import appInfoReducer from "store/reducers/appinfoSlice";
import appointmentReducer from "store/reducers/appointmentSlice";
import authReducer from "store/reducers/authSlice";
import dialogReducer from "store/reducers/dialogSlice";
import documentReducer from "store/reducers/documentSlice";
import projectReducer from "store/reducers/projectSlice";
import snackBarReducer from "store/reducers/snackBarSlice";
import userReducer from "store/reducers/userSlice";

export const store = configureStore({
   reducer: {
      appinfo: appInfoReducer,
      auth: authReducer,
      user: userReducer,
      announcement: announcementReducer,
      appointment: appointmentReducer,
      project: projectReducer,
      document: documentReducer,
      snack: snackBarReducer,
      dialog: dialogReducer,
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
         immutableCheck: false,
         serializableCheck: false,
      }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
