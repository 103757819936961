const NotFound = () => {
   return (
      <div>
         <h4>This page isn't available</h4>
         <p>The link you followed may be broken, or the page may have been removed.</p>
      </div>
   );
};

export default NotFound;
