import { red } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, PaletteColorOptions, ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "store";
import App from "./App";
import "./index.css";
const { palette } = createTheme();

declare module "@mui/material/styles" {
   interface Palette {
      tertiary: PaletteColorOptions;
   }
   interface PaletteOptions {
      tertiary: PaletteColorOptions;
   }
}

declare module "@mui/material/Button" {
   interface ButtonPropsColorOverrides {
      tertiary: true;
   }
}

const MTheme = createTheme({
   palette: {
      primary: {
         main: "#4EB4D4",
         light: "#AECED5",
         dark: "#3b8196",
         contrastText: "#fff",
      },
      secondary: {
         main: "#AECED5",
      },
      tertiary: palette.augmentColor({ color: red }),
   },
});

ReactDOM.render(
   <React.StrictMode>
      <Provider store={store}>
         <ThemeProvider theme={MTheme}>
            <CssBaseline />
            <App />
         </ThemeProvider>
      </Provider>
   </React.StrictMode>,
   document.getElementById("root")
);
