import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DialogInitialState, DialogModel } from "store/types/global";

const initialState: DialogModel = DialogInitialState;

export const dialogSlice = createSlice({
   name: "dialog",
   initialState,
   reducers: {
      open: (state, action: PayloadAction<DialogModel>) => {
         state.open = true;
         state.title = action.payload.title;
         state.message = action.payload.message;
         state.onConfirm = action.payload.onConfirm;
      },
      close: (state) => {
         state.open = false;
         state.title = "";
         state.message = "";
      },
   },
});

export const dialogActions = dialogSlice.actions;
export default dialogSlice.reducer;
