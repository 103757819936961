import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProjects } from "store/actions/projectActions";
import { ProjectDto, ProjectModel } from "store/types/project";

const initialState: ProjectDto = {
   isLoading: false,
   current: undefined,
   index: undefined,
};

export const projectSlice = createSlice({
   name: "projects",
   initialState,
   reducers: {
      create: (state, action: PayloadAction<ProjectModel>) => {
         state.index?.push(action.payload);
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getProjects.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getProjects.fulfilled, (state, action) => {
            state.index = action.payload.result;
         });
   },
});

export const projectActions = projectSlice.actions;
export default projectSlice.reducer;
