export type SnackModel = {
   message: string;
   success: boolean;
   open: boolean;
};

export const SnackInitialState: SnackModel = {
   message: "",
   success: false,
   open: false,
};

export type DialogModel = {
   open?: boolean;
   title?: string;
   message?: string;
   onClose?: any;
   onCancel?: any;
   onConfirm?: any;
};

export const DialogInitialState: DialogModel = {
   open: false,
};
