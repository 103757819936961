import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_DOCUMENT, API_DOCUMENT_INDEX, API_DOCUMENT_STATUS } from "core/endpoints";
import axios from "utils/axios";

export const getDocuments = createAsyncThunk("document/index", async () => {
   const res = await axios.get(API_DOCUMENT_INDEX);
   return res.data;
});

export const updateDocumentStatus = createAsyncThunk("document/update/status", async (data: any, thunkAPI) => {
   try {
      const res = await axios.put(API_DOCUMENT_STATUS, data);
      if (!res.data.status) throw res.data;
      return res.data;
   } catch (err) {
      return err;
   }
});

export const deleteDocument = createAsyncThunk("document/delete", async (data: any, thunkAPI) => {
   try {
      const res = await axios.delete(API_DOCUMENT, { data });
      if (!res.data.status) throw res.data;
      return res.data;
   } catch (err) {
      return err;
   }
});
