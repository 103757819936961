import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import BMS from "components/animation/bmsLoad";

const lazyLoad = () => {
   return (
      <div
         style={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
         }}
      >
         <BMS />
         <Box sx={{ width: "30vw" }}>
            <LinearProgress color="primary" />
         </Box>
      </div>
   );
};

export default lazyLoad;
