import axios from "axios";
import { store } from "store";

let API = axios.create({
   baseURL: process.env.REACT_APP_API_URL, // localhost:7001
});

API.interceptors.request.use(
   (config) => {
      const state = store.getState();
      const token = state.auth.access_token || null;

      if (token !== null) {
         config.headers!.Authorization = `Bearer ${token}`;
      }
      return config;
   },
   (error) => {
      return Promise.reject(error);
   }
);

export default API;
