import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCurrentUser, getUsers } from "store/actions/userActions";
import { UserDto, UserInformationModel, UserModel } from "store/types/user";

const current: UserInformationModel = {
   _id: "",
   avatar: "",
   valid_id: "",
   id_number: "",
   first_name: "",
   middle_name: "",
   last_name: "",
   citizenship: "",
   contact_number: "",
   gender: "",
   civil_status: "",
   birthplace: "",
   birthdate: "",
   occupation: "",
   address: "",
   user_id: "",
};

const initialState: UserDto = {
   isLoading: false,
   current: null,
   index: null,
};

export const userSlice = createSlice({
   name: "users",
   initialState,
   reducers: {
      create: (state, action: PayloadAction<UserModel>) => {
         state.index?.push(action.payload);
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getUsers.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUsers.fulfilled, (state, action) => {
            state.index = action.payload.result;
         });

      builder
         .addCase(getCurrentUser.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCurrentUser.fulfilled, (state, action) => {
            state.current = action.payload.result;
         });
   },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
