import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Box from "@mui/system/Box";
import { STORAGE } from "core/endpoints";
import { ADMIN, EMERGENCY, HEALTH, OFFICER, PERMISSION } from "core/permissions";
import React, { useState } from "react";
import { loginHandler } from "store/actions/authActions";
import { AppInfoDto } from "store/types/appinfo";
import { LoginModel } from "store/types/auth";
import { SnackInitialState, SnackModel } from "store/types/global";
import { Alert } from "utils/alert";
import { useAppDispatch, useAppSelector, useNavigate } from "utils/redux-hooks";
import { Validate } from "utils/validate";

const LoginInitialState: LoginModel = {
   username: "",
   password: "",
};

const Login = () => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const AppInfo = useAppSelector<AppInfoDto>((state) => state.appinfo);
   const LOGO = `${STORAGE}/${AppInfo.current?.barangay_logo}`;

   const [data, setData] = useState<LoginModel>(LoginInitialState);
   const [snack, setSnack] = useState<SnackModel>(SnackInitialState);

   const handleInputChange = (prop: keyof LoginModel) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, [prop]: event.target.value });
   };

   const submit = (event: React.FormEvent) => {
      if (!Validate.string(data.username) || !Validate.string(data.password)) return;
      event.preventDefault();

      dispatch(loginHandler(data))
         .unwrap()
         .then((res) => {
            let location = "";

            if (!res.status) {
               setSnack({
                  message: res.message,
                  success: false,
                  open: true,
               });

               return;
            }

            if (!PERMISSION.some((i) => i?.toString() == res.result.level)) {
               setSnack({
                  message: "You're not allowed to use this app.",
                  success: false,
                  open: true,
               });
               return;
            }

            if (res.result.level == ADMIN || res.result.level == OFFICER) {
               location = "/dashboard";
            }

            if (res.result.level == HEALTH) {
               location = "/dashboard/announcement";
            }

            if (res.result.level == EMERGENCY) {
               location = "/dashboard/emergency";
            }

            return navigate(location);
         })
         .catch((err) => {
            setSnack({
               message: err.message,
               success: false,
               open: true,
            });
         });
   };

   return (
      <>
         <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={6000}
            open={snack.open}
            onClose={() => setSnack({ ...snack, open: false })}
            key={"topcenter"}
         >
            <Alert
               onClose={() => setSnack({ ...snack, open: false })}
               severity={snack.success ? "success" : "error"}
               sx={{ width: "100%" }}
            >
               {snack.message}
            </Alert>
         </Snackbar>
         <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "100vh" }}
         >
            <Paper
               sx={{
                  width: 320,
                  height: 400,
                  padding: 5,
               }}
               elevation={5}
            >
               <Grid container>
                  <Grid item xs={12} p={5}>
                     <Avatar
                        alt="BMS"
                        src={LOGO}
                        sx={{
                           width: 150,
                           height: 100,
                           margin: "auto",
                        }}
                        variant="square"
                     />
                  </Grid>
                  <Grid item>
                     <Box component="form" autoComplete="off">
                        <TextField
                           fullWidth
                           id="Username"
                           label="Username"
                           type="text"
                           onChange={handleInputChange("username")}
                           size="small"
                           sx={{ pb: 1 }}
                           required
                        />
                        <TextField
                           fullWidth
                           id="Password"
                           label="Password"
                           type="password"
                           onChange={handleInputChange("password")}
                           size="small"
                           sx={{ pb: 1 }}
                           required
                        />
                        <Button fullWidth variant="contained" type="submit" onClick={submit}>
                           Let me in
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Paper>
         </Grid>
      </>
   );
};

export default Login;
