import { lazy } from "react";

const DELAY = 1000;

export const Admin = lazy(async () => {
   const [moduleExports] = await Promise.all([
      import("views/private/index"),
      new Promise((resolve) => setTimeout(resolve, DELAY)),
   ]);
   return moduleExports;
});

export const Announcement = lazy(async () => {
   const [moduleExports] = await Promise.all([
      import("views/private/announcement"),
      new Promise((resolve) => setTimeout(resolve, DELAY)),
   ]);
   return moduleExports;
});

export const Appointment = lazy(async () => {
   const [moduleExports] = await Promise.all([
      import("views/private/appointment"),
      new Promise((resolve) => setTimeout(resolve, DELAY)),
   ]);
   return moduleExports;
});

export const Dashboard = lazy(async () => {
   const [moduleExports] = await Promise.all([
      import("views/private/dashboard"),
      new Promise((resolve) => setTimeout(resolve, DELAY)),
   ]);
   return moduleExports;
});

export const Emergency = lazy(async () => {
   const [moduleExports] = await Promise.all([
      import("views/private/emergency"),
      new Promise((resolve) => setTimeout(resolve, DELAY)),
   ]);
   return moduleExports;
});

export const Resident = lazy(async () => {
   const [moduleExports] = await Promise.all([
      import("views/private/resident"),
      new Promise((resolve) => setTimeout(resolve, DELAY)),
   ]);
   return moduleExports;
});

export const Official = lazy(async () => {
   const [moduleExports] = await Promise.all([
      import("views/private/official"),
      new Promise((resolve) => setTimeout(resolve, DELAY)),
   ]);
   return moduleExports;
});

export const Project = lazy(async () => {
   const [moduleExports] = await Promise.all([
      import("views/private/project"),
      new Promise((resolve) => setTimeout(resolve, DELAY)),
   ]);
   return moduleExports;
});

export const Document = lazy(async () => {
   const [moduleExports] = await Promise.all([
      import("views/private/document"),
      new Promise((resolve) => setTimeout(resolve, DELAY)),
   ]);
   return moduleExports;
});

export const Management = lazy(async () => {
   const [moduleExports] = await Promise.all([
      import("views/private/management"),
      new Promise((resolve) => setTimeout(resolve, DELAY)),
   ]);
   return moduleExports;
});

export const AppInfo = lazy(async () => {
   const [moduleExports] = await Promise.all([
      import("views/private/appinfo"),
      new Promise((resolve) => setTimeout(resolve, DELAY)),
   ]);
   return moduleExports;
});
