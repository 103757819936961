import NotFound from "components/notFound";
import {
   Admin,
   Announcement,
   AppInfo,
   Appointment,
   Dashboard,
   Document,
   Emergency,
   Management,
   Official,
   Project,
   Resident,
} from "core/pages";
import {
   AUTH_ANNOUNCEMENT,
   AUTH_APPINFO,
   AUTH_APPOINTMENT,
   AUTH_DOCUMENT,
   AUTH_MANAGEMENT,
   AUTH_OFFICIAL,
   AUTH_PROJECT,
   AUTH_RESCUE,
   AUTH_RESIDENT,
   PERMISSION,
} from "core/permissions";
import { useEffect } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import { authHandler } from "store/actions/authActions";
import { useAppDispatch, useAppSelector, useNavigate } from "utils/redux-hooks";
import Login from "views/public/login";

const AppRoutes = () => {
   const { isLoggedIn, access_token, level } = useAppSelector((state) => state.auth);
   const dispatch = useAppDispatch();
   const navigate = useNavigate();

   useEffect(() => {
      if (access_token) return;
      const GetAsyncedData = async () => {
         const { payload } = await dispatch(authHandler());
         return !payload && navigate("/login");
      };
      GetAsyncedData();
   }, [isLoggedIn, access_token, navigate, dispatch]);

   const routes: RouteObject[] = [
      {
         path: "/dashboard",
         element: !PERMISSION.some((i) => i?.toString() == level) ? <Login /> : <Admin />,
         children: [
            {
               path: "announcement",
               element: AUTH_ANNOUNCEMENT.some((i) => i?.toString() == level) && <Announcement />,
            },
            {
               path: "appointment",
               element: AUTH_APPOINTMENT.some((i) => i?.toString() == level) && <Appointment />,
            },
            {
               path: "document",
               element: AUTH_DOCUMENT.some((i) => i?.toString() == level) && <Document />,
            },
            {
               path: "emergency",
               element: AUTH_RESCUE.some((i) => i?.toString() == level) && <Emergency />,
            },
            {
               path: "official",
               element: AUTH_OFFICIAL.some((i) => i?.toString() == level) && <Official />,
            },
            {
               path: "project",
               element: AUTH_PROJECT.some((i) => i?.toString() == level) && <Project />,
            },
            {
               path: "resident",
               element: AUTH_RESIDENT.some((i) => i?.toString() == level) && <Resident />,
            },
            {
               path: "appinfo",
               element: AUTH_APPINFO.some((i) => i?.toString() == level) && <AppInfo />,
            },
            {
               path: "management",
               element: AUTH_MANAGEMENT.some((i) => i?.toString() == level) && <Management />,
            },
            {
               path: "",
               element: AUTH_MANAGEMENT.some((i) => i?.toString() == level) && <Dashboard />,
            },
         ],
      },
      {
         path: "/",
         element: <Login />,
         children: [
            {
               path: "login",
               element: <Login />,
            },
         ],
      },
      {
         path: "*",
         element: <NotFound />,
      },
   ];

   return useRoutes(routes);
};

export default AppRoutes;
